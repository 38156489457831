<template>
  <div class="rss-form-container">
    <div class="form-group">
      <label for="region">
        <img class="icon" src="/images/region.png" alt="Region" />
        Region:
      </label>
      <select v-model="region" @change="fetchRSSFeed">
        <option value="AMERICAS">Americas</option>
        <option value="EMEA">EMEA</option>
        <option value="APAC">APAC</option>
      </select>
    </div>
    <div class="form-group">
      <label for="scope">
        <img class="icon" src="/images/scope.png" alt="Scope" />
        Scope:</label>
      <select v-model="scope" @change="fetchRSSFeed">
        <option value="News">News</option>
        <option value="Consultation" :disabled="consultation !== 'open'">Consultation</option>
        <option value="Law and Regulation">Law and Regulation</option>
      </select>
    </div>
    <div class="form-group">
      <label for="industry">
        <img  class="icon" src="/images/industry.png" alt="Industry" />
        Industry:</label>
      <select v-model="industry" @change="fetchRSSFeed">
        <option value="Financial Services">Financial Services</option>
        <option value="Healthcare">Healthcare</option>
        <option value="Government and Defense">Government and Defense</option>
        <option value="Energy">Energy</option>
        <option value="Telecommunications">Telecommunications</option>
        <option value="Critical Infrastructure">Critical Infrastructure</option>

      </select>
    </div>
    <div class="form-group">
      <label for="consultation">
        <img   class="icon" src="/images/consultation.png" alt="Consultation" />
        Consultation:</label>
      <select v-model="consultation" @change="fetchRSSFeed">
        <option value="open">Open</option>
        <option value="close">Close</option>
      </select>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      region: 'AMERICAS',
      scope: 'News',
      industry: 'Financial Services',
      consultation: 'open'
    };
  },
  methods: {
    async fetchRSSFeed() {
      // Your existing fetchRSSFeed method
      try {
        const response = await axios.get('https://globalregwatch.com/api/routes/rssitems', {
          params: {
            region: this.region,
            industry: this.industry,
            scope: this.scope,
          }
        });

        const rssFeedData = response.data; // Store fetched data

        if (Array.isArray(rssFeedData) && rssFeedData.length === 0) {
          console.log('Response is empty');
          // Handle the case when the response is empty
        } else if (typeof rssFeedData === 'object' && Object.keys(rssFeedData).length === 0) {
          console.log('Response is empty');
          // Handle the case when the response is empty
        } else {
          // Emit an event to pass the fetched data to the parent component
          this.$emit('updateRssFeedData', rssFeedData);
          console.log('Data emitted from RssForm:', rssFeedData);
        }
      } catch (error) {
        console.error('Error fetching RSS feed:', error);
        // Log the error and handle it appropriately
      }
    }
  },
  
};
</script>



<style>
/* Styles for RssForm.vue */
.rss-form-container {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.icon {
  width: 20px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 5px; /* Adjust spacing between image and text */
}
</style>
