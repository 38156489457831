<template>
  <div class="rss-feed-results">

    <ul>
      <li v-for="(item, index) in displayedFeed" :key="index">
        <div class="rss-item">
          <a :href="item.link" target="_blank" class="title" v-html="highlightSearchedWord(item.title)"></a>
          <p class="description">{{ item.description }}</p>
          <div class="date-time">
            <p class="date">Date: {{ formatDate(item.pubDate) }}</p>
            <p class="time">Time: {{ formatTime(item.pubDate) }}</p>
          </div>
        </div>
        <!-- Separator line -->
        <hr class="separator">
      </li>
    </ul>
    <!-- Pagination buttons -->
    <div class="pagination">
      <button v-if="currentPage > 1" @click="goToPage(currentPage - 1)">Previous</button>
      <button v-for="pageNumber in visiblePages" :key="pageNumber" @click="goToPage(pageNumber)" :class="{ 'active': pageNumber === currentPage }">
        {{ pageNumber }}
      </button>
      <button v-if="currentPage < totalPages" @click="goToPage(currentPage + 1)">Next</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rssFeedData: {
      type: Array,
      default: () => []
    },
    searchQuery: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 6,
      maxVisiblePages: 5 // Adjust this number to change the maximum number of visible page buttons
    };
  },
  computed: {
    displayedFeed() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.rssFeedData.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.rssFeedData.length / this.itemsPerPage);
    },
    visiblePages() {
      const startPage = Math.max(1, this.currentPage - Math.floor(this.maxVisiblePages / 2));
      const endPage = Math.min(this.totalPages, startPage + this.maxVisiblePages - 1);
      return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    }
  },
  methods: {
    // Method to format date (assuming pubDate is a string)
    formatDate(pubDate) {
      const date = new Date(pubDate);
      return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    },
    // Method to format time (assuming pubDate is a string)
    formatTime(pubDate) {
      const date = new Date(pubDate);
      return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    },
    // Method to highlight the searched word in the title
    highlightSearchedWord(title) {
  if (!this.searchQuery) return title;
  const regex = new RegExp(this.searchQuery, 'gi');
  return title.replace(regex, match => `<span style="background-color: yellow; color: black; font-weight: bold;">${match}</span>`);
},

    // Method to navigate to a specific page
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
    }
  }
};
</script>

<style scoped>
/* Add your styling for RssFeedResults.vue here */
.rss-feed-results {
  margin-top: 20px;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

.rss-item {
  margin-bottom: 20px;
}

.title {
  font-weight: bold;
  text-decoration: none;
  color: #007bff;
}

.title:hover {
  text-decoration: underline;
}

.description {
  color: #000;
}

.date-time {
  color: #000;
}

.separator {
  margin: 10px 0;
  border: 1px solid #ccc;
}

.date,
.time {
  margin: 5px 0;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center; /* Center align the buttons */
}

.pagination button {
  margin-right: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination .active {
  background-color: #0056b3;
}


</style>
