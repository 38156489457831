<template>
  <div>
    <canvas ref="lineChartCanvas"></canvas>
  </div>
</template>

<script>
import axios from 'axios';
import { Chart } from 'chart.js/auto';

export default {
  data() {
    return {
      lineChart: null
    };
  },
  mounted() {
    // Wait for next tick to ensure component is fully rendered
    this.$nextTick(() => {
      if (!this.lineChart) {
      this.fetchLatestRSSCounts();
    }
    });
  },
  methods: {
    async fetchLatestRSSCounts() {
      try {
        const response = await axios.get('https://globalregwatch.com/api/routes/latest-rss-counts');
        const data = response.data;
        console.log(data)

        // Extracting labels and counts from the response data
        const labels = data.map(item => item.fetch_date);
        const counts = data.map(item => item.link_count);

        // Render line chart
        this.renderLineChart(labels, counts);
      } catch (error) {
        console.error('Error fetching latest RSS counts:', error);
      }
    },
    renderLineChart(labels, counts) {
      const ctx = this.$refs.lineChartCanvas.getContext('2d');
      this.lineChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: [{
            label: 'RSS Item Counts',
            data: counts,
            borderColor: 'rgba(54, 162, 235, 1)', // Adjust color as needed
            borderWidth: 2,
            fill: false
          }]
        },
        options: {
          responsive: true,
        //   maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      });
    }
  }
};
</script>

<style>
/* Styles for line chart */
</style>
