<template>
  <div class="main-dashboard">
    <div class="header">
      <div class="logo">
        <router-link to="/" class="title" @click="resetState">Global Reg Watch</router-link>
      </div>
      <SearchBar @search="handleSearch" />
    </div>

    <div class="sidebar">
      <div class="menu-item">
        <img src="/images/dashboard.png" alt="Dashboard" />
        <span>Dashboard</span>
      </div>
      <div class="rss-form-container">
        <RssForm @updateRssFeedData="updateRssFeedData" />
      </div>
      <div class="menu-item">
        <img src="/images/setting.png" alt="Setting" />
        <span>Setting</span>
      </div>
    </div>

    <div class="content">
      <div class="weekly-activity">
        <RssFeedResults :rssFeedData="filteredRssFeedData" :searchQuery="searchQuery" />
      </div>

      <div class="analytics">
        <h2>Analytics</h2>
        <div class="charts">
          <RegionChart />
          <ScopeChart />
          <IndustryChart />
          <LineChart />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import axios from 'axios';
import RegionChart from "./RegionChart.vue";
import ScopeChart from "./ScopeChart.vue";
import IndustryChart from "./IndustryChart.vue";
import LineChart from "./LineChart.vue";
import RssForm from './RssForm.vue'
import RssFeedResults from './RssFeedResults.vue'
import SearchBar from './SearchBar.vue'

export default defineComponent({
  name: "HomePage",
  components: {
    RssForm,
    RegionChart,
    ScopeChart,
    IndustryChart,
    LineChart,
    RssFeedResults,
    SearchBar,
  },
  data() {
    return {
      rssFeedData: [], // Initialize rssFeedData property
      searchQuery: ''
    };
  },
  computed: {
    filteredRssFeedData() {
      // Filter the RSS feed data based on the search query in the title
      return this.rssFeedData.filter(item =>
        item.title.toLowerCase().includes(this.searchQuery)
      );
    }
  },
  methods: {
    async fetchLatestRSSItems() {
      try {
        const response = await axios.get('https://globalregwatch.com/api/routes/latest-rss-items');
        this.rssFeedData = response.data;
      } catch (error) {
        console.error('Error fetching latest RSS items:', error);
        // Handle the error appropriately
      }
    },
    updateRssFeedData(data) {
      // Update rssFeedData property with the fetched data
      this.rssFeedData = data;
    },
    handleSearch(query) {
      // Update the searchQuery property with the search query
      this.searchQuery = query.toLowerCase();
    },
    resetState() {
      this.searchQuery = '';
      this.fetchLatestRSSItems();
    }
  },
  created() {
    // Fetch the latest RSS items when the component is created
    this.fetchLatestRSSItems();
  }
});
</script>

<style scoped>
.main-dashboard {
  display: flex;
  flex-direction: column;
  font-family: 'Arial', sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 15px 30px;
  border-bottom: 1px solid #ccc;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.logo {
  display: flex;
  align-items: center;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  text-decoration: none;
}

.search-bar-container {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.search-bar-container input {
  width: 60%;
  max-width: 500px;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
}

.search-bar-container button {
  margin-left: 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search-bar-container button:hover {
  background-color: #0056b3;
}

.sidebar {
  width: 250px;
  background-color: #f8f9fa;
  padding-top: 100px;
  position: fixed;
  top: 0;
  bottom: 0;
  border-right: 1px solid #ccc;
  overflow-y: auto;
}

.content {
  padding-top: 100px; /* Adjust the top padding to account for the fixed header */
  padding-left: 270px; /* Adjusted from 250px to add space between sidebar and content */
  padding-right: 30px; /* Add some right padding for spacing */
  background-color: #f0f2f5;
  min-height: 100vh; /* Ensure the content area fills the viewport height */
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  color: #333;
}

.menu-item img {
  margin-right: 10px;
  height: 20px;
}

.menu-item:hover {
  background-color: #e9ecef;
}

.rss-form-container {
  padding: 10px;
}

.weekly-activity,
.analytics {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.weekly-activity h2,
.analytics h2 {
  margin-bottom: 20px;
  font-size: 22px;
  color: #333;
}

.charts {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
  .charts {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 992px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .search-bar-container {
    width: 100%;
    justify-content: flex-start;
  }

  .sidebar {
    width: 200px;
    padding-top: 60px; /* Adjust padding to account for smaller screen */
  }

  .content {
    padding-left: 220px; /* Adjust padding to account for smaller sidebar */
  }
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
  }

  .title {
    font-size: 20px;
  }

  .search-bar-container input {
    width: 80%;
  }

  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
    border-right: none;
  }

  .content {
    padding: 20px 10px;
    padding-top: 120px; /* Adjust padding to account for fixed header */
  }
}

@media (max-width: 576px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .title {
    font-size: 18px;
  }

  .search-bar-container input {
    width: 100%;
  }

  .search-bar-container button {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }

  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
    padding-top: 20px; /* Adjust padding to account for smaller screen */
    border-right: none;
  }

  .menu-item {
    justify-content: center;
  }

  .content {
    padding: 20px 10px;
    padding-top: 140px; /* Adjust padding to account for fixed header */
  }

  .weekly-activity, .analytics {
    padding: 15px;
  }

  .charts {
    flex-direction: column;
    align-items: center;
  }
}
</style>
