<template>
  <div>
    <canvas ref="industryChartCanvas"></canvas>
  </div>
</template>

<script>
import axios from 'axios';
import { Chart } from 'chart.js/auto';

export default {
  data() {
    return {
      industryChart: null
    };
  },
  mounted() {
    // Wait for next tick to ensure component is fully rendered
    this.$nextTick(() => {
      this.fetchIndustryData();
    });
  },
  methods: {
    async fetchIndustryData() {
      try {
        const response = await axios.get('https://globalregwatch.com/api/routes/industry-counts');
        const data = response.data;

        const labels = data.map(item => item.name);
        const counts = data.map(item => item.count);

        this.renderIndustryChart(labels, counts);
      } catch (error) {
        console.error('Error fetching industry counts:', error);
      }
    },
    renderIndustryChart(labels, counts) {
      const ctx = this.$refs.industryChartCanvas.getContext('2d');
      this.industryChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [{
            data: counts,
            backgroundColor: [
              'rgba(255, 99, 132, 0.7)',
              'rgba(54, 162, 235, 0.7)',
              'rgba(255, 206, 86, 0.7)',
              // Add more colors as needed
            ]
          }]
        },
        options: {
          plugins: {
            legend: {
              display: false // Hides the legend
            }
          }
        }
      });
    }
  }
};
</script>

<style>
/* Styles for industry doughnut chart */
</style>
