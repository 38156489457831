<template>
  <div class="search-bar">
    <input type="text" v-model="searchQuery" @keyup.enter="search" placeholder="Search..."/>
    <button @click="search" ><img src="/images/search.png" alt="" class="image"></button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchQuery: ''
    };
  },
  methods: {
    search() {
      // Emit the search event with the search query
      this.$emit('search', this.searchQuery);
    }
  }
}
</script>

<style scoped>
/* Styles for search bar */
.search-bar {
  display: flex;
  align-items: center;
}
.search-bar input {
  flex: 1;
  margin-right: 2px;
  height:18px;
}
.image{
  height: 18px;
}
</style>
